export const generateRandomString = (length) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";
  for (let i = 0; i < length; i++) {
    randomString += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return randomString;
};
export const scope = [
  "ugc-image-upload",
  "user-read-playback-state",
  "user-modify-playback-state",
  "user-read-currently-playing",
  "app-remote-control",
  "streaming",
  "playlist-read-private",
  "playlist-read-collaborative",
  "playlist-modify-private",
  "playlist-modify-public",
  "user-follow-modify",
  "user-follow-read",
  "user-read-playback-position",
  "user-top-read",
  "user-read-recently-played",
  "user-library-modify",
  "user-library-read",
  "user-read-email",
  "user-read-private",
].join(" ");
export const Spotifylogin = () => {
  const scope = [
    "ugc-image-upload",
    "user-read-playback-state",
    "user-modify-playback-state",
    "user-read-currently-playing",
    "app-remote-control",
    "streaming",
    "playlist-read-private",
    "playlist-read-collaborative",
    "playlist-modify-private",
    "playlist-modify-public",
    "user-follow-modify",
    "user-follow-read",
    "user-read-playback-position",
    "user-top-read",
    "user-read-recently-played",
    "user-library-modify",
    "user-library-read",
    "user-read-email",
    "user-read-private",
  ].join(" ");
  const authUrl = `https://accounts.spotify.com/authorize?response_type=code&client_id=0a9995dd76d44757a722335e07ca7844&scope=${scope}&redirect_uri=https://royclaudio.com/Services&state=${generateRandomString(16)}`;
  window.location.replace(authUrl);
};
export const SpotifyDataHolder = [
  {
    id: "2dAtxRO4t3kYM387NCpPFL",
    name: "Never Come Down",
    artists: "Merkules, Millyz",
    duration_ms: 216025,
    popularity: 32,
    preview_url: "https://p.scdn.co/mp3-preview/057487cf7a211c5f06863ed68b9ac0c45386b660?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0201334b51420cd3ca18c154fe",
    danceability: 0.481,
    energy: 0.577,
    valence: 0.193,
    added_at: "2024-10-03T00:29:11Z",
    link: "https://open.spotify.com/track/2dAtxRO4t3kYM387NCpPFL",
    userID: "royclaud",
    uri: "spotify:track:11dFghVXANMlKmJXsNCbNl",
    genres: "hiphop,dance, rock",
  },
  {
    id: "0TZsy6XheL9cSxuX7yYqCO",
    name: "Which Side are You On?",
    artists: "Pete Seeger",
    duration_ms: 129800,
    popularity: 38,
    preview_url: "https://p.scdn.co/mp3-preview/077f1af61f3e97dcefbc2917beba7d8641e5c599?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026528dcc259ef64aea8630ae3",
    danceability: 0.496,
    energy: 0.245,
    valence: 0.592,
    added_at: "2024-10-02T13:26:22Z",
    link: "https://open.spotify.com/track/0TZsy6XheL9cSxuX7yYqCO",
    userID: "royclaud",
    uri: "spotify:track:11dFghVXANMlKmJXsNCbNl",
    genres: "hiphop,dance, worship",
  },
  {
    id: "4rwpZEcnalkuhPyGkEdhu0",
    name: "Scary Monsters and Nice Sprites",
    artists: "Skrillex",
    duration_ms: 243353,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/9df66cfc3a6c32d051b286f47aba4890bbf5b297?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0281cfaf3b1359f7b579ea05e5",
    danceability: 0.523,
    energy: 0.944,
    valence: 0.324,
    added_at: "2024-10-01T14:17:24Z",
    link: "https://open.spotify.com/track/4rwpZEcnalkuhPyGkEdhu0",
    userID: "royclaud",
    uri: "spotify:track:11dFghVXANMlKmJXsNCbNl",
    genres: "hiphop,dance, worship",
  },
  {
    id: "1ZdhOMWyFR8Iv9eylMGYg2",
    name: "Mount Everest",
    artists: "Labrinth",
    duration_ms: 157497,
    popularity: 79,
    preview_url: "https://p.scdn.co/mp3-preview/002d61108f8dadbe8160849a01976f5e15f85985?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e4c03429788f0aff263a5fc6",
    danceability: 0.494,
    energy: 0.428,
    valence: 0.44,
    added_at: "2024-09-30T22:01:48Z",
    link: "https://open.spotify.com/track/1ZdhOMWyFR8Iv9eylMGYg2",
    userID: "royclaud",
    uri: "spotify:track:11dFghVXANMlKmJXsNCbNl",
    genres: "hiphop,dance, worship",
  },
  {
    id: "1VutwUjHPwEELxHkwdhMkz",
    name: "Clandestina (feat. Emma Peters) - JVSTIN Remix",
    artists: "FILV, Edmofo, Emma Peters, Jvstin",
    duration_ms: 145233,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/94e0fac0b074d7358ed4486ed5538a5eeb6c5c54?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025f69558590bf91cd0eb860d7",
    danceability: 0.788,
    energy: 0.402,
    valence: 0.805,
    added_at: "2024-09-28T18:02:46Z",
    link: "https://open.spotify.com/track/1VutwUjHPwEELxHkwdhMkz",
    userID: "royclaud",
    uri: "spotify:track:11dFghVXANMlKmJXsNCbNl",
    genres: "hiphop,dance, worship",
  },
];

export function GroupGenres(tracks) {
  let genres = [
    ...new Set(
      tracks
        .flatMap(
          (artist) =>
            artist.genres
              ? artist.genres.split(",").map((g) => g.trim().replace(/^\s*$/, "other")) // Replace empty string with "Other"
              : ["other"] // If no genres, use "Other"
        )
        .map((g) => g.trim())
        .filter((g) => g !== "" && g.length > 3)
        .sort((a, b) => a.localeCompare(b))
    ),
  ].map((genre) => genre);

  let genreGroups = {};
  console.log();
  genres.forEach((genre) => {
    // Default to the first word if no two-word combo match and not exactly two words
    let genreWords = genre.split(" ");
    let groupKey = genreWords[0];

    if (!groupKey) {
      groupKey = genreWords[0];
    }

    // Add to the genre group if the groupKey length is valid
    if (!genreGroups[groupKey]) {
      genreGroups[groupKey] = [];
    }
    genreGroups[groupKey].push(genre);
  });
  console.log(Object.keys(genreGroups));
  return Object.keys(genreGroups)
    .filter((b) => tracks.filter((a) => a.genres === b).length >= 5)
    .sort((a, b) => a.localeCompare(b));
}
