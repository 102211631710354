import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Home from "./pages/Home";
import Articles from "./pages/Articles";
import Services from "./pages/Services";
import Threads from "./pages/Threads";
import Research from "./pages/Research";

function App() {
  const homeContent = [
    {
      Id: 2,
      Img: "RD",
      Title: "Research & Developments",
      Byline: "I like learning about new fields, here is where you'll find the latest research I am learning.",
      ToLink: "Research",
    },
    {
      Id: 1,
      Img: "RR",
      Title: "Articles",
      Byline: "If anything catches my eye, you will find my review for it here.",
      ToLink: "Articles",
    },
  ];

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home content={homeContent} />} />
          <Route path="/Articles" element={<Articles />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Threads" element={<Threads />} />
          <Route path="/Research" element={<Research />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
//npm install react-chartjs-2 chart.js
//npm install react
