export const data = {
  labels: ["Danceability", "Popularity", "Energy", "Positiveness"],
  datasets: [
    {
      label: "s",
      data: [1, 0, 1, 0],
      backgroundColor: ["rgba(255, 99, 132, 0.6)", "rgba(54, 162, 235, 0.6)", "rgba(255, 206, 86, 0.6)", "rgba(75, 192, 192, 0.6)"],
      borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)"],
      borderWidth: 1,
    },
  ],
};
export const trackdata = {
  labels: ["Danceability", "Popularity", "Energy", "Positiveness"],
  datasets: [
    {
      label: "s",
      data: [1, 0, 1, 0],
      backgroundColor: ["rgba(255, 99, 132, 0.6)", "rgba(54, 162, 235, 0.6)", "rgba(255, 206, 86, 0.6)", "rgba(75, 192, 192, 0.6)"],
      borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)"],
      borderWidth: 1,
    },
  ],
};
export const options = {
  responsive: true,
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  scales: {
    x: {
      min: 0,
      max: 1,
      ticks: {
        color: "rgb(30, 215, 96)",
        callback: function (value, index, ticks) {
          return value * 100 + "%";
        },
      },
      grid: {
        color: "rgba(30, 215, 96, 0.2)",
      },
    },
    y: {
      ticks: {
        color: "rgb(30, 215, 96)",
      },
      grid: {
        color: "rgba(30, 215, 96, 0.2)",
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },

    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          return `${tooltipItem.label}: ${(tooltipItem.raw * 100).toFixed(0) + "%"}`;
        },
      },
    },
  },
};
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const date_data = {
  labels: months,
  datasets: [
    {
      label: "added at",
      data: [0],
      backgroundColor: ["rgba(255, 99, 132, 0.6)", "rgba(54, 162, 235, 0.6)", "rgba(255, 206, 86, 0.6)", "rgba(75, 192, 192, 0.6)"],
      borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)"],
      borderWidth: 1,
    },
  ],
};

export const date_options = {
  responsive: true,
  indexAxis: "x",
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  scales: {
    x: {
      ticks: {
        color: "rgb(30, 215, 96)",
      },
      grid: {
        color: "rgba(30, 215, 96, 0.2)",
      },
    },
    y: {
      ticks: {
        color: "rgb(30, 215, 96)",
      },
      grid: {
        color: "rgba(30, 215, 96, 0.2)",
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },

    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          return `Added: ${tooltipItem.raw} Songs`;
        },
      },
    },
  },
};
export function setmaingraph(tracks) {
  var popularity = ((tracks.reduce((sum, track) => sum + track.popularity, 0) / tracks.length) * 0.01).toFixed(2);
  var danceability = (tracks.reduce((sum, track) => sum + track.danceability, 0) / tracks.length).toFixed(2);
  var energy = (tracks.reduce((sum, track) => sum + track.energy, 0) / tracks.length).toFixed(2);
  var valence = (tracks.reduce((sum, track) => sum + track.valence, 0) / tracks.length).toFixed(2);
  data.datasets[0].data = [danceability, popularity, energy, valence];
  var datesorder = tracks.sort((a, b) => (a.added_at.substring(0, 7) < b.added_at.substring(0, 7) ? -1 : 1));
  var dates = datesorder.map((a) => a.added_at.substring(0, 7));
  var uniqueDates = [...new Set(dates)];
  var uniquData = uniqueDates.map((date) => dates.filter((d) => d === date).length);
  date_data.labels = uniqueDates;
  date_data.datasets[0].data = uniquData;
}
export function setTrackGraph(track) {
  trackdata.datasets[0].data = [track.danceability.toFixed(2), (track.popularity * 0.01).toFixed(2), track.energy.toFixed(2), track.valence.toFixed(2)];
}
