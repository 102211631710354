import React, { useEffect, useState, useCallback } from "react";
import "../assets/styles/Blog.css";
import "../assets/styles/NFT.css";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Bar, Bar as Bar1 } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { SpotifyDataHolder, GroupGenres, Spotifylogin } from "../components/Spotify";
import { AsciiMsg } from "../components/Utiles";
import { data, options, date_data, date_options, trackdata, setTrackGraph, setmaingraph } from "../components/Chartdata";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement } from "chart.js";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);
const ServicesSkills = () => {
  const [tracks, setTracks] = useState([]);
  const [mPlaylist, setplaylist] = useState();
  const [spType, setType] = useState();
  const [loading, setLoading] = useState(true);
  const [UserStatus, setStatus] = useState(false);
  const [hasTracks, setHasTracks] = useState(false);
  const [selectedTrackId, setSelectedTrackId] = useState();
  const [mOrder, setOrder] = useState("");
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
  const audioRefs = React.useRef([]);
  const tracksPerPage = 25;
  const maxPagesToShow = 3;
  const location = useLocation();
  const totalPages = Math.ceil(tracks.length / tracksPerPage);
  let generalizedGenres = GroupGenres(tracks);

  const getVisiblePages = () => {
    const pages = [];
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
    if (endPage - startPage + 1 < maxPagesToShow && totalPages > maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  useEffect(() => {
    const trackdata = JSON.parse(localStorage.getItem("tracks"));
    const userKey = JSON.parse(localStorage.getItem("userKey"));
    const playlist = JSON.parse(localStorage.getItem("playlist"));

    const GetSongs = async () => {
      let postsData = [];
      try {
        const response = await axios.get(`/GetMusic`);
        postsData = response.data || [];
      } catch (error) {
        console.log("Error fetching music data:", error);
      }
      let tracksToSet = postsData.length > 0 ? postsData : SpotifyDataHolder;
      tracksToSet = tracksToSet.map((a) => ({
        ...a,
        genres: Object.entries(
          a.genres
            .replace(/hip hop/g, "hip-hop")
            .split(",")
            .flatMap((a) => a.split(" "))
            .reduce((acc, val) => {
              acc[val] = (acc[val] || 0) + 1;
              return acc;
            }, {})
        ).reduce((max, [key, val]) => (val > max[1] ? [key, val] : max), ["", 0])[0],
      }));
      tracksToSet = tracksToSet.map((a) => ({
        ...a,
        genres: Object.entries(
          a.genres
            .replace(/hip hop/g, "hip-hop")
            .split(",")
            .flatMap((a) => a.split(" "))
            .reduce((acc, val) => {
              acc[val] = (acc[val] || 0) + 1;
              return acc;
            }, {})
        ).reduce((max, [key, val]) => (val > max[1] ? [key, val] : max), ["", 0])[0],
      }));
      localStorage.setItem("tracks", JSON.stringify(tracksToSet));
      setmaingraph(tracksToSet);
      setOrder("Latest");
      setType(["tracks", "saved"]);
      setTracks(tracksToSet);
      setHasTracks(true);
      setLoading(false);
    };
    if (userKey && Date.now() <= Number(userKey.expires_in)) {
      setStatus(true);
    } else {
      setStatus(false);
      localStorage.removeItem("userKey");
    }
    if (playlist) {
      setplaylist(playlist);
    }
    if (trackdata) {
      setType(["tracks", "saved"]);
      setOrder("Latest");
      setmaingraph(trackdata);
      setTracks(trackdata);
      setHasTracks(true);
      setLoading(false);
    } else {
      GetSongs();
    }
  }, []); //check user status, GetMusic or Load storage Music
  const cleanUrl = () => {
    const urlObj = new URL(window.location.href);
    urlObj.search = "";
    window.location.href = urlObj.toString();
  };
  useEffect(() => {
    const code = new URLSearchParams(location.search).get("code");
    const GetKey = async () => {
      try {
        console.log("Establishing user key...");
        const response = await axios.post(`/GetKey/${code}`);
        let key = response.data;
        key.expires_in = Date.now() + 3600 * 1000;
        localStorage.setItem("userKey", JSON.stringify(key));
        cleanUrl();
        console.log("Establishing complete...");
      } catch (error) {
        console.error("Error fetching key:", error);
        cleanUrl();
      }
    };
    if (code) {
      GetKey();
    }
  }, [location]);

  async function fetchWebApi(endpoint, method, body, access_token) {
    const response = await fetch(`https://api.spotify.com/v1/${endpoint}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      method,
      body: body ? JSON.stringify(body) : null,
    });

    if (!response.ok) {
      const errorResponse = await response.json().catch(() => ({})); // Prevent crash on parsing errors
      return {
        error: "API_ERROR",
        message: `Spotify API responded with status ${response.status}`,
        details: errorResponse,
        status: response.status, // Include the status code
      };
    }
    const data = await response.json();
    return data;
  }
  const getTracks = useCallback(() => {
    console.log("Establishing songs...");
    const Asyncfun = async () => {
      try {
        const userKey = JSON.parse(localStorage.getItem("userKey"));
        if (userKey && Date.now() <= Number(userKey.expires_in)) {
          setLoading(true);
          const userTracksResponse = await fetchWebApi("me/tracks?limit=1&offset=0", "GET", null, userKey.access_token);
          const userTotal = userTracksResponse.total;
          if (userTotal === undefined) {
            throw new Error(`Failed to retrieve user total tracks: ${userTotal}`);
          }
          // Fetch user ID
          const userResponse = await fetchWebApi("me", "GET", null, userKey.access_token);
          const userId = userResponse.id;
          if (!userId) {
            throw new Error(`Failed to retrieve user ID: ${userId}`);
          }
          let offset = 0;
          let totalsongs = [];
          let limit = 50;
          while (offset < userTotal) {
            // Fetch user's songs
            const songsResponse = await fetchWebApi(`me/tracks?limit=${limit}&offset=${offset}`, "GET", null, userKey.access_token);
            const songs = songsResponse.items;
            if (!songs) {
              throw new Error(`Failed to retrieve songs: ${JSON.stringify(songsResponse)}`);
            }
            const topTracksIds = songs.map((track) => track.track.id).join(",");
            const featuresResponse = await fetchWebApi(`audio-features?ids=${topTracksIds}`, "GET", null, userKey.access_token);
            const songFeatures = featuresResponse.audio_features;
            if (!songFeatures) {
              throw new Error(`Failed to retrieve audio features: ${JSON.stringify(featuresResponse)}`);
            }
            for (const track of songs) {
              const matchingFeat = songFeatures.find((audioFeature) => audioFeature.id === track.track.id);
              if (matchingFeat) {
                totalsongs.push({
                  id: track.track.id,
                  name: track.track.name || "unknown_name",
                  artists: track.track.artists.map((artist) => artist.name).join(",") || "unknown_artists",
                  duration_ms: track.track.duration_ms || 0,
                  popularity: track.track.popularity || 0,
                  preview_url: track.track.preview_url || "null",
                  image: track.track.album.images?.[1]?.url || "unknown_image",
                  danceability: matchingFeat.danceability || 0,
                  energy: matchingFeat.energy || 0,
                  valence: matchingFeat.valence || 0,
                  added_at: track.added_at || "1776-07-04",
                  link: track.track.external_urls?.spotify || "unknown_link",
                  userId: userId,
                  genres: "No Genres",
                  artistsIDs: track.track.artists.map((artist) => artist.id),
                });
              }
            }
            offset += limit;
          }
          const memoartist = [];
          offset = 0;
          var uniqueArtist = [...new Set(totalsongs.flatMap((track) => track.artistsIDs))];
          while (offset < uniqueArtist.length) {
            const calls = uniqueArtist.slice(offset, offset + 50).join(",");
            const artistResponse = await fetchWebApi(`artists?ids=${calls}`, "GET", null, userKey.access_token);
            if (!artistResponse) {
              throw new Error(`Failed to retrieve artist details: ${calls}`);
            }
            memoartist.push(...artistResponse.artists);
            offset += 50;
          }
          var final = totalsongs.map((a) => ({
            ...a,
            genres: memoartist
              .filter((b) => a.artistsIDs.includes(b.id))
              .map((b) => b.genres)
              .join(","),
          }));
          final = final.map((a) => ({
            ...a,
            genres: Object.entries(
              a.genres
                .replace("hip hop", "hip-hop")
                .split(",")
                .flatMap((a) => a.split(" "))
                .reduce((acc, val) => {
                  acc[val] = (acc[val] || 0) + 1;
                  return acc;
                }, {})
            ).reduce((max, [key, val]) => (val > max[1] ? [key, val] : max), ["", 0])[0],
          }));
          final = final.map((a) => ({
            ...a,
            genres: Object.entries(
              a.genres
                .replace(/hip hop/g, "hip-hop")
                .split(",")
                .flatMap((a) => a.split(" "))
                .reduce((acc, val) => {
                  acc[val] = (acc[val] || 0) + 1;
                  return acc;
                }, {})
            ).reduce((max, [key, val]) => (val > max[1] ? [key, val] : max), ["", 0])[0],
          }));
          localStorage.setItem("tracks", JSON.stringify(final));

          setTracks(final);
          setHasTracks(true);
          setLoading(false);
          setStatus(true);
        } else {
          setStatus(false);
        }
      } catch (error) {
        console.error("Error retrieving songs:", error);
        setStatus(false);
        setHasTracks(true);
        setLoading(false);
        setStatus(true);
      }
    };
    Asyncfun();
    console.log("Establishing complete");
  }, []);
  const saveSongs = useCallback((saveTracks, order, genre) => {
    var name = `${order}-${genre}`;
    var answer = window.confirm(`Are you sure you want to save: ${name}?`);
    const Asyncfun = async () => {
      try {
        const userKey = JSON.parse(localStorage.getItem("userKey"));
        if (userKey && Date.now() <= Number(userKey.expires_in)) {
          const tracksToSave = saveTracks
            .map((track) => "spotify:track:" + track.id)
            .slice(0, 100)
            .join(",");
          const response = await axios.post(`/saveSongs/${userKey.access_token}/${tracksToSave}/${name}`);
          localStorage.setItem("playlist", JSON.stringify(response.data));
        }
      } catch (error) {
        console.error("Error saving songs:", error);
      }
    };
    if (answer) {
      Asyncfun();
    }
  }, []);
  const handleSelectChange = (event) => {
    const order = event.target.value;
    const orderTracks = [...tracks];
    const sortFunctions = {
      Groovy: (a, b) => b.danceability - a.danceability,
      Popular: (a, b) => b.popularity - a.popularity,
      Energetic: (a, b) => b.energy - a.energy,
      Positive: (a, b) => b.valence - a.valence,
      Latest: (a, b) => new Date(b.added_at) - new Date(a.added_at),
    };
    const sortFunction = sortFunctions[order] || sortFunctions.Latest;
    orderTracks.sort(sortFunction);
    setTracks(orderTracks);
    setHasTracks(true);
    setLoading(false);
    setOrder(order);
    setCurrentPage(1);
    setSelectedTrackId(0);
  };
  const handleSeeAnalysis = useCallback(
    (trackId) => {
      const matchingTrack = tracks.find((a) => a.id === trackId);
      if (matchingTrack) {
        setTrackGraph(matchingTrack);
      }
      setSelectedTrackId(trackId);
    },
    [tracks]
  );
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  function cleartracks() {
    localStorage.clear();
    cleanUrl();
  }
  const handleFilter = (genre) => {
    let allTracks = JSON.parse(localStorage.getItem("tracks")) || [];
    let orderTracks = [...allTracks];
    if (genre !== "") {
      orderTracks = orderTracks.filter(
        (track) =>
          track.genres
            ?.split(",")
            .map((g) => g.toLowerCase())
            .some((g) => g === genre.toLowerCase()) // Check for exact match of the genre (case-insensitive)
      );
    }
    setSelectedGenres(genre);
    setmaingraph(orderTracks);
    setTracks(orderTracks);
    setCurrentPage(1);
    setHasTracks(orderTracks.length > 0);
    setLoading(false);
  };

  const getTop = useCallback(
    (type, length) => {
      const Asyncfun = async (tp, lng) => {
        try {
          const userKey = JSON.parse(localStorage.getItem("userKey"));
          if (userKey && Date.now() <= Number(userKey.expires_in)) {
            setLoading(true);
            const userTracksResponse = await fetchWebApi(`me/top/${tp}?time_range=${lng}&limit=1&offset=0`, "GET", null, userKey.access_token);
            let userTotal = userTracksResponse.total;
            const userResponse = await fetchWebApi("me", "GET", null, userKey.access_token);
            const userId = userResponse.id;
            let offset = 0;
            let totalsongs = [];
            let limit = 50;
            let topTracksIds;
            let featuresResponse;
            let songFeatures;
            let answer;
            if (userTotal > 1500) {
              answer = window.confirm(`If the total number of songs exceeds 1,500 at ${userTotal}, the amount will be reduced to 1,500.`);
              userTotal = 1500;
            } else {
              answer = true;
            }
            while (offset < userTotal && answer) {
              // Fetch user's songs
              const songsResponse = await fetchWebApi(`me/top/${tp}?time_range=${lng}&limit=${limit}&offset=${offset}`, "GET", null, userKey.access_token);
              const songs = songsResponse.items;
              if (!songs) {
                throw new Error(`Failed to retrieve songs: ${JSON.stringify(songsResponse)}`);
              }
              if (type === "tracks") {
                topTracksIds = songs.map((track) => track.id).join(",");
                featuresResponse = await fetchWebApi(`audio-features?ids=${topTracksIds}`, "GET", null, userKey.access_token);
                songFeatures = featuresResponse.audio_features;
              }
              for (const track of songs) {
                const matchingFeat = songFeatures?.find((audioFeature) => audioFeature.id === track.id);
                if (matchingFeat) {
                  totalsongs.push({
                    id: track.id,
                    name: track.name || "unknown_name",
                    artists: "by " + track.artists.map((artist) => artist.name).join(",") || "Single Artist",
                    duration_ms: track.duration_ms || 0,
                    popularity: track.popularity || 0,
                    preview_url: track.preview_url || "null",
                    image: track.album.images?.[1]?.url || "unknown_image",
                    danceability: matchingFeat.danceability || 0,
                    energy: matchingFeat.energy || 0,
                    valence: matchingFeat.valence || 0,
                    added_at: track.album.release_date || "1776-07-04",
                    link: track.external_urls?.spotify || "unknown_link",
                    userId: userId,
                    genres: "music,musician,artist",
                    artistsIDs: track.artists?.map((artist) => artist.id) || "na",
                  });
                } else if (type === "artists") {
                  totalsongs.push({
                    id: track.id,
                    name: track.name || "unknown_name",
                    artists: "",
                    duration_ms: 0,
                    popularity: track.popularity || 0,
                    preview_url: track.preview_url || "null",
                    image: track.images?.[1]?.url || "unknown_image",
                    danceability: 0,
                    energy: 0,
                    valence: 0,
                    added_at: track.added_at || "From Birth",
                    link: track.external_urls?.spotify || "unknown_link",
                    userId: userId,
                    genres: tracks.genres,
                    artistsIDs: "na",
                  });
                }
              }
              offset += limit;
            }
            const memoartist = [];
            offset = 0;
            let final;
            var uniqueArtist = [...new Set(totalsongs.flatMap((track) => track.artistsIDs))];
            if (type === "tracks") {
              while (offset < uniqueArtist.length) {
                const calls = uniqueArtist.slice(offset, offset + 50).join(",");
                const artistResponse = await fetchWebApi(`artists?ids=${calls}`, "GET", null, userKey.access_token);
                if (!artistResponse) {
                  throw new Error(`Failed to retrieve artist details: ${calls}`);
                }
                memoartist.push(...artistResponse.artists);
                offset += 50;
              }
              final = totalsongs.map((a) => ({
                ...a,
                genres: memoartist
                  .filter((b) => a.artistsIDs.includes(b.id))
                  .map((b) => b.genres)
                  .join(","),
              }));
            } else {
              final = totalsongs;
            }
            final = final.map((a) => ({
              ...a,
              genres: Object.entries(
                a.genres
                  .replace(/hip hop/g, "hip-hop")
                  .split(",")
                  .flatMap((a) => a.split(" "))
                  .reduce((acc, val) => {
                    acc[val] = (acc[val] || 0) + 1;
                    return acc;
                  }, {})
              ).reduce((max, [key, val]) => (val > max[1] ? [key, val] : max), ["", 0])[0],
            }));
            localStorage.setItem("tracks", JSON.stringify(final));
            setType([type, length]);
            setTracks(final);
            setHasTracks(true);
            setLoading(false);
            setStatus(true);
          } else {
            setStatus(false);
          }
        } catch (error) {
          console.error("Error retrieving songs:", error);
          setStatus(false);
          setHasTracks(true);
          setLoading(false);
          setStatus(true);
        }
      };
      Asyncfun(type, length);
      console.log("Establishing complete");
    },
    [tracks.genres]
  );
  const renderTrack = React.useMemo(() => {
    const handleTrackEnd = () => {
      if (currentTrackIndex !== null) {
        document.getElementById(currentTrackIndex).classList.remove("play1");
      }
      if (currentTrackIndex !== null && currentTrackIndex < tracks.length - 1) {
        playTrack(currentTrackIndex + 1);
        document.getElementById(currentTrackIndex + 1).classList.add("play1");
      } else {
        setCurrentTrackIndex(null);
      }
    };
    const playTrack = (index) => {
      if (currentTrackIndex !== null && currentTrackIndex !== index) {
        audioRefs.current[currentTrackIndex]?.pause();
        audioRefs.current[currentTrackIndex].currentTime = 0;
        document.getElementById(currentTrackIndex).classList.remove("play1");
      }
      if (currentTrackIndex === index) {
        audioRefs.current[index]?.pause();
        document.getElementById(index).classList.remove("play1");
        setCurrentTrackIndex(null);
      } else {
        setCurrentTrackIndex(index);
        document.getElementById(index).classList.add("play1");
        audioRefs.current[index]?.play();
      }
    };
    const start = (currentPage - 1) * tracksPerPage;
    const end = start + tracksPerPage;
    const paginatedTracks = tracks.slice(start, end);

    return paginatedTracks.map((track, index) => (
      <li id={index} className={"track-style d-flex flex-column justify-content-center align-content-center " + (selectedTrackId === track.id ? "active" : "")} key={index}>
        <div className="d-flex flex-column justify-content-center align-content-center cursor-pointer ">
          <p className="t-name">{track.name.split(" - ")[0].split(/(\s*\([^)]*\)\s+)/)[0]}</p>
          <p className="t-artist">{track.artists.split(", ")[0]}</p>
          <div className="t-img img-wrapper">
            <img src={track.image} alt={`${track.name} track cover`} />
          </div>
        </div>
        <audio ref={(el) => (audioRefs.current[index] = el)} onEnded={handleTrackEnd} key={track.preview_url}>
          <source src={track.preview_url} type="audio/mpeg" />
        </audio>
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div role="button" onClick={() => playTrack(index)} className="genre t-ctrl ">
            {document.getElementById(index)?.classList.contains("play") ? "Pause" : "Play"}
          </div>
          <button className={"genre t-stats "} onClick={() => handleSeeAnalysis(selectedTrackId === track.id ? 0 : track.id)}>
            {selectedTrackId === track.id ? "Collapse" : "Expand"} Stats
          </button>
        </div>
        {selectedTrackId === track.id && (
          <div className={"d-flex flex-column justify-content-center align-content-center mx-2"} style={{ marginTop: "50px" }}>
            <div className="track-chart">
              <Bar1 data={trackdata} options={options} />
            </div>
            <table style={{ maxWidth: "300px" }}>
              <tbody>
                <tr>
                  <th>Popularity</th>
                  <td align="right">{track.popularity}%</td>
                </tr>
                <tr>
                  <th>Danceability</th>
                  <td align="right">{(track.danceability * 100).toFixed(0)}%</td>
                </tr>

                <tr>
                  <th>Energy</th>
                  <td align="right">{(track.energy * 100).toFixed(0)}%</td>
                </tr>
                <tr>
                  <th>Positiveness</th>
                  <td align="right">{(track.valence * 100).toFixed(0)}%</td>
                </tr>
                <tr>
                  <th>Length</th>
                  <td align="right">
                    {Math.floor(track.duration_ms / 60000)}:{((track.duration_ms % 60000) / 1000).toFixed(0)}
                  </td>
                </tr>
                <tr>
                  <th>Date Added</th>
                  <td align="right">{track.added_at.slice(0, 10)}</td>
                </tr>
                <tr>
                  <th>Genres</th>
                  <td align="right">{track.genres}</td>
                </tr>
                <tr>
                  <th>link</th>
                  <td align="right">
                    <a href={track.link}>Listen on spotify</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </li>
    ));
  }, [currentPage, selectedTrackId, tracks, currentTrackIndex, handleSeeAnalysis]);
  if (loading) {
    return (
      <div className="container-fluid d-flex flex-wrap justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <div className="container-fluid">
      <div className="d-flex flex-wrap justify-content-between align-items-center m-2" style={{ borderBottom: "1px solid black" }}>
        <h1>
          {mOrder} {tracks.length} {selectedGenres !== "" && selectedGenres} {spType && spType[1].replace("_", " ")} {spType && spType[0]}
        </h1>
        <button onClick={() => document.getElementById("offcanvasBottom").classList.toggle("show")}>📊 {document.getElementById("offcanvasBottom")?.classList.contains("show") ? "Collapse" : "Expand"} Chart</button>
        {!UserStatus && (
          <button onClick={Spotifylogin}>
            On the list? <img src="assets/pushpin.svg" alt="spotify logo" className="mx-1" />
            Login
          </button>
        )}
      </div>
      <ul className="track">
        {mPlaylist !== "" && mPlaylist !== undefined && (
          <li className={"track-style d-flex flex-column justify-content-center align-content-center "}>
            <div className="d-flex flex-column justify-content-center align-content-center cursor-pointer ">
              <p className="t-name">Playlist: {mPlaylist.name}</p>
              <p className="t-artist">{mPlaylist.artists}</p>
              <div className="t-img img-wrapper">
                <img src={mPlaylist.image} alt={`${mPlaylist.name} playlist cover`} />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <button className={"genre t-stats "} onClick={() => window.open(mPlaylist.link, "_blank")}>
                Visit Playlist
              </button>
            </div>
          </li>
        )}
      </ul>
      <div className="my-3">
        {hasTracks ? (
          <div className="">
            <div className="">
              {UserStatus && (
                <div className="d-flex flex-wrap justify-content-between align-items-center mx-2" style={{ borderBottom: "1px solid black" }}>
                  <div className="">
                    <div>Get Top Artists:</div>
                    <button className="genre" onClick={() => getTop("artists", "long_term")}>
                      ~1 year
                    </button>
                    <button className="genre" onClick={() => getTop("artists", "medium_term")}>
                      6 months
                    </button>
                    <button className="genre" onClick={() => getTop("artists", "short_term")}>
                      4 weeks
                    </button>
                  </div>

                  <div className="">
                    <div>Track Controls:</div>
                    <button className="genre" onClick={() => getTracks()}>
                      Get Saved Songs
                    </button>
                    {spType && spType[0] === "tracks" && (
                      <button className="genre" onClick={() => saveSongs(tracks, mOrder, selectedGenres.replace(/\s/g, "-"))}>
                        Save Tracks To Playlist
                      </button>
                    )}
                    <button className="genre" onClick={() => cleartracks()}>
                      Reset Cache
                    </button>
                  </div>
                  <div className="">
                    <div>Get Top Tracks:</div>
                    <button className="genre" onClick={() => getTop("tracks", "long_term")}>
                      ~1 year
                    </button>
                    <button className="genre" onClick={() => getTop("tracks", "medium_term")}>
                      6 months
                    </button>
                    <button className="genre" onClick={() => getTop("tracks", "short_term")}>
                      4 weeks
                    </button>
                  </div>
                </div>
              )}
              <div className="d-flex flex-wrap justify-content-between align-items-center mx-2">
                <div className="">
                  <div>Your Genres: {generalizedGenres.length} Genres</div>
                  {tracks[0]?.genres != null && selectedGenres !== "" && selectedGenres && (
                    <button className="mx-1" onClick={() => handleFilter("")}>
                      Reset Genres
                    </button>
                  )}
                  {tracks[0]?.genres != null &&
                    generalizedGenres
                      .sort((a, b) => {
                        const countA = tracks.filter((track) => track.genres === a).length;
                        const countB = tracks.filter((track) => track.genres === b).length;
                        return countB - countA;
                      })
                      // Only show genres that match any of the selected genres, or if no filter is applied, show all.
                      .filter((a) => selectedGenres.length === 0 || selectedGenres.includes(a))
                      .map((genreCategory) => (
                        <button key={genreCategory} className={`mx-1 ${selectedGenres.includes(genreCategory) ? "selected" : ""}`} onClick={() => handleFilter(genreCategory)}>
                          {genreCategory} {tracks.filter((track) => track.genres === genreCategory).length}
                        </button>
                      ))}
                </div>
                <select aria-label="Order Selection" className="my-2" onChange={handleSelectChange}>
                  <option value="Latest">Sort by Date Added: latest</option>
                  <option value="Groovy">Sort by Danceability</option>
                  <option value="Popular">Sort by Popularity</option>
                  <option value="Energetic">Sort by Energy</option>
                  <option value="Positive">Sort by Positiveness</option>
                </select>
              </div>
            </div>

            <ul className="track">{renderTrack}</ul>
            <div>
              <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                Previous
              </button>
              {currentPage > Math.ceil(maxPagesToShow / 2) && <button onClick={() => handlePageChange(1)}>1</button>}
              {currentPage > Math.ceil(maxPagesToShow / 2) && <span>...</span>}
              {getVisiblePages().map((page) => (
                <button key={page} onClick={() => handlePageChange(page)} className={page === currentPage ? "play" : ""}>
                  {page}
                </button>
              ))}
              {currentPage < totalPages - Math.floor(maxPagesToShow / 2) && <span>...</span>}
              {currentPage < totalPages - Math.floor(maxPagesToShow / 2) && <button onClick={() => handlePageChange(totalPages)}>{totalPages}</button>}
              <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                Next
              </button>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column  justify-content-center align-items-center">
            <span>No songs whoops...</span>
            <button className="genre" onClick={() => cleartracks()}>
              Reset Tracks
            </button>
          </div>
        )}
      </div>
      <div className="offcanvas offcanvas-bottom" tabIndex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title text-center" id="offcanvasBottomLabel">
            Average Track Traits Summary Charts
          </h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => document.getElementById("offcanvasBottom").classList.toggle("show")}></button>
        </div>
        <div className="offcanvas-body ">
          <h5 className="text-center">Average Track Traits</h5>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Bar className="" style={{ width: "100%", height: "360px" }} data={data} options={options} />
          </div>
          <h5 className="text-center">Songs Added By Date</h5>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Line className="" style={{ width: "100%", height: "360px" }} data={date_data} options={date_options} />
          </div>
        </div>
      </div>
    </div>
  );
};
console.log(AsciiMsg(new Date(Date.now()).toDateString()));
export default ServicesSkills;
