import React, { useEffect, useState } from "react";
import "../assets/styles/Blog.css";
import "../assets/styles/NFT.css";
import {  Col, Form, Row } from "react-bootstrap";

import axios from "axios";
import { useLocation } from "react-router-dom";

const login = () => {
    const scope = [
        "threads_basic", "threads_content_publish", "threads_read_replies", "threads_manage_replies", "threads_manage_insights",
    ].join(" ");
    const authUrl =
        `https://threads.net/oauth/authorize?` +
        `response_type=code&` +
        `client_id=8431583420188363&` +
        `scope=${scope}&` +
        `redirect_uri=https://royclaudio.com/Threads`;
    window.location.href = authUrl;
};
const ddata1 = [
    {
        id: "2dAtxRO4t3kYM387NCpPFL",
        name: "Never Come Down",
        artists: "Merkules, Millyz",
        duration_ms: 216025,
        popularity: 32,
        preview_url: "https://p.scdn.co/mp3-preview/057487cf7a211c5f06863ed68b9ac0c45386b660?cid=0a9995dd76d44757a722335e07ca7844",
        image: "https://i.scdn.co/image/ab67616d00001e0201334b51420cd3ca18c154fe",
        danceability: 0.481,
        energy: 0.577,
        valence: 0.193,
        added_at: "2024-10-03T00:29:11Z",
        link: "https://open.spotify.com/track/2dAtxRO4t3kYM387NCpPFL",
        userID: "royclaud",
        uri: "spotify:track:11dFghVXANMlKmJXsNCbNl",
    },
    {
        id: "0TZsy6XheL9cSxuX7yYqCO",
        name: "Which Side are You On?",
        artists: "Pete Seeger",
        duration_ms: 129800,
        popularity: 38,
        preview_url: "https://p.scdn.co/mp3-preview/077f1af61f3e97dcefbc2917beba7d8641e5c599?cid=0a9995dd76d44757a722335e07ca7844",
        image: "https://i.scdn.co/image/ab67616d00001e026528dcc259ef64aea8630ae3",
        danceability: 0.496,
        energy: 0.245,
        valence: 0.592,
        added_at: "2024-10-02T13:26:22Z",
        link: "https://open.spotify.com/track/0TZsy6XheL9cSxuX7yYqCO",
        userID: "royclaud",
        uri: "spotify:track:11dFghVXANMlKmJXsNCbNl",
    },
    {
        id: "4rwpZEcnalkuhPyGkEdhu0",
        name: "Scary Monsters and Nice Sprites",
        artists: "Skrillex",
        duration_ms: 243353,
        popularity: 57,
        preview_url: "https://p.scdn.co/mp3-preview/9df66cfc3a6c32d051b286f47aba4890bbf5b297?cid=0a9995dd76d44757a722335e07ca7844",
        image: "https://i.scdn.co/image/ab67616d00001e0281cfaf3b1359f7b579ea05e5",
        danceability: 0.523,
        energy: 0.944,
        valence: 0.324,
        added_at: "2024-10-01T14:17:24Z",
        link: "https://open.spotify.com/track/4rwpZEcnalkuhPyGkEdhu0",
        userID: "royclaud",
        uri: "spotify:track:11dFghVXANMlKmJXsNCbNl",
    },
    {
        id: "1ZdhOMWyFR8Iv9eylMGYg2",
        name: "Mount Everest",
        artists: "Labrinth",
        duration_ms: 157497,
        popularity: 79,
        preview_url: "https://p.scdn.co/mp3-preview/002d61108f8dadbe8160849a01976f5e15f85985?cid=0a9995dd76d44757a722335e07ca7844",
        image: "https://i.scdn.co/image/ab67616d00001e02e4c03429788f0aff263a5fc6",
        danceability: 0.494,
        energy: 0.428,
        valence: 0.44,
        added_at: "2024-09-30T22:01:48Z",
        link: "https://open.spotify.com/track/1ZdhOMWyFR8Iv9eylMGYg2",
        userID: "royclaud",
        uri: "spotify:track:11dFghVXANMlKmJXsNCbNl",
    },
    {
        id: "1VutwUjHPwEELxHkwdhMkz",
        name: "Clandestina (feat. Emma Peters) - JVSTIN Remix",
        artists: "FILV, Edmofo, Emma Peters, Jvstin",
        duration_ms: 145233,
        popularity: 63,
        preview_url: "https://p.scdn.co/mp3-preview/94e0fac0b074d7358ed4486ed5538a5eeb6c5c54?cid=0a9995dd76d44757a722335e07ca7844",
        image: "https://i.scdn.co/image/ab67616d00001e025f69558590bf91cd0eb860d7",
        danceability: 0.788,
        energy: 0.402,
        valence: 0.805,
        added_at: "2024-09-28T18:02:46Z",
        link: "https://open.spotify.com/track/1VutwUjHPwEELxHkwdhMkz",
        userID: "royclaud",
        uri: "spotify:track:11dFghVXANMlKmJXsNCbNl",
    },
];
var ddata = [];
ddata.push(...ddata1);
ddata.push(...ddata1);
ddata.push(...ddata1);
const Threads = () => {
    const [hasUser, setLoadingUser] = useState(false);
    const location = useLocation();
    useEffect(() => {
        const code = new URLSearchParams(location.search).get("code");
        const userKey = localStorage.getItem("userThreadKey") ? JSON.parse(localStorage.getItem("userThreadKey")) : null;
        const createdAt = localStorage.getItem("createdThreadAt") ? JSON.parse(localStorage.getItem("createdThreadAt")) : null;
        const cleanUrl = (body) => {
            const urlObj = new URL(window.location.href);
            urlObj.search = body ? body : "";
            window.location.href = urlObj.toString();
        };
        const GetKey = async () => {
            try {
                if (code) {
                    console.log("No keys exists and user code present, establishing user key...");
                    const response = await axios.post(`/GetThreadKey/${code.replace("#_", "")}`);
                    const key = response.data;
                    localStorage.setItem("userThreadKey", JSON.stringify(key));
                    localStorage.setItem("createdThreadAt", JSON.stringify(Date.now() + 5183944 * 1000));
                    cleanUrl("Establishing_complete");
                } else {
                    console.log("Refreshing user key...");
                    const response = await axios.post(`/GetThreadKey/${userKey.access_token}/1`);
                    const key = response.data;
                    localStorage.setItem("userThreadKey", JSON.stringify(key));
                    localStorage.setItem("createdThreadAt", JSON.stringify(Date.now() + 5183944 * 1000));
                }

            } catch (error) {
                console.log("Error_fetching_key", error);
            }
        };
        if (userKey && createdAt) {
            if (Date.now() <= Number(createdAt)) {
                console.log("Establishing complete, Key exists and is not expired");
                setLoadingUser(true);
            } else {
                GetKey();
            }
        } else if (code) {
            console.log("No keys exists and user code present, establishing user key...");
            GetKey();
        }

    }, [location]);
    return (
        <div className="container d-flex flex-column justify-content-center align-items-center">
            <h1 className="text-center mb-3">Threads</h1>
            {hasUser && (<Form>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <button>Create Posts</button>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <button>Delete Posts</button>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridPassword">
                        <button>Get User Posts</button>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <button>Get Post Analytics</button>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <button>Get User Analytics</button>
                    </Form.Group>
                </Row>
            </Form>)}
            {!hasUser && (<div>
                <Form.Label>Are you on the list?</Form.Label>
                <br></br>
                <button onClick={() => login()}>Login</button>
            </div>)}
        </div>
    );
};

export default Threads;
