import React, { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "../../assets/styles/Layout.css";

const AppNavbar = () => {
  const location = useLocation(); // Get the current location using useLocation hook
  const [activeKey, setActiveKey] = useState("/");
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (eventKey) => {
    setIsOpen(false);
    setActiveKey(eventKey);
  };
  React.useEffect(() => {
    setActiveKey(location.pathname);
  }, [location]);
  return (
    <Navbar expand="lg" bg={`${window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"}`} data-bs-theme={`${window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"}`}>
      <div className="container-fluid">
        <Navbar.Brand as={Link} to="/">
          <img src="apple-touch-icon.png" height="50" className="d-inline-block align-top" alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setIsOpen(!isOpen)} />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" in={isOpen} onSelect={handleSelect}        >
          <Nav className="" activeKey={activeKey}>
            <Nav.Link as={Link} to="/" eventKey="/" onClick={() => setIsOpen(false)}>Main</Nav.Link>
            <Nav.Link as={Link} to="/Articles" eventKey="/Articles" onClick={() => setIsOpen(false)}>Articles</Nav.Link>
            <Nav.Link as={Link} to="/Research" eventKey="/Research" onClick={() => setIsOpen(false)}>Research</Nav.Link>
            <NavDropdown title="Mini-Projects" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/Services" eventKey="/Services" onClick={() => setIsOpen(false)}>Spotify API</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/Threads" eventKey="/Threads" onClick={() => setIsOpen(false)}>Threads API</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default AppNavbar;
