import React, { useState } from "react";
import { Card } from "react-bootstrap";
import "../assets/styles/Home.css";
import { Link } from "react-router-dom";
const Home = ({ content }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };
  const svgPlaceholder = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIj4KPHJlY3Qgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHN0eWxlPSJmaWxsOmJsYWNrOyIvPjwvc3ZnPg=="; // Base64 encoded SVG

  return (
    <div className="container-fluid mobile">
      <h1 className="text-center">Roy Claudio's Website</h1>
      <ul>
        {content.map((item, idx) => (
          <li key={idx} className="list">
            <Card>
              <Card.Body>
                <Card.Title>{item.Title}</Card.Title>
                <div className="card-img-wrapper">
                  <Card.Img loading="lazy" src={isLoading ? svgPlaceholder : item.Img === "RD" ? "assets/RC1.png" : item.Img === "RR" ? "assets/RC.JPG" : "assets/RC1.png"} alt={item.Img} onLoad={handleImageLoad} onError={() => setIsLoading(false)} />
                </div>
                <Card.Text>{item.Byline}</Card.Text>
                <Link to={"/" + item.ToLink}>
                  <button>Read More</button>
                </Link>
              </Card.Body>
            </Card>
          </li>
        ))}
      </ul>
      <ul>
        <li key="spotify" className="list">
          <Card>
            <Card.Body>
              <Card.Title>Spotify API</Card.Title>
              <div className="card-img-wrapper">
                <Card.Img src="assets/pushpin.svg" alt="Roy claudio website background." />
              </div>
              <Card.Text>Exploring the spotify API.</Card.Text>
              <Link to="/Services">
                <button>Read More</button>
              </Link>
            </Card.Body>
          </Card>
        </li>
        <li key="threads" className="list">
          <Card>
            <Card.Body>
              <Card.Title>Threads API</Card.Title>
              <div className="card-img-wrapper">
                <Card.Img src="assets/images/threads.svg" alt="Roy claudio website background." />
              </div>
              <Card.Text>Exploring the Threads API.</Card.Text>
              <Link to="/Threads">
                <button>Read More</button>
              </Link>
            </Card.Body>
          </Card>
        </li>
      </ul>

      {/* <div className="container hm">
        <Row className="d-flex justify-content-center gap-3">
          {content.map((blog, idx) => (
            <Col key={idx}>
              <Card className="mpcard mx-0 px-0">
                <div className="card-img-wrapper">
                      <Card.Img loading="lazy" src={isLoading ? svgPlaceholder : blog.Img === "RD" ? "assets/RC1.png" : blog.Img === "RR" ? "assets/RC.JPG" : "assets/RC1.png"} alt={blog.Img} onLoad={handleImageLoad} onError={() => setIsLoading(false)} className="card-img" width="100%" height="auto" />

                </div>
                <Card.Body>
                  <Card.Title>{blog.Title}</Card.Title>
                  <Card.Text>{blog.Byline}</Card.Text>
                  <button as={Link} to={"/" + blog.ToLink}>
                    {blog.ToLink === "Services" ? "Services" : blog.ToLink}
                  </button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row> 
      </div>*/}
    </div>
  );
};

export default Home;
